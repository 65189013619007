import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h2 style={{
                  color: '#242222',
                  fontSize: '3.5em',  // Increase font size
                  lineHeight: '1.2',  // Adjust line height for better spacing
                  marginBottom: '20px'  // Add some space below the title
                }}>
                  {props.data ? props.data.title.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < props.data.title.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  )) : "Loading"}
                </h2>
                <p style={{
                  color: '#242222',
                  fontSize: '1.5em',  // Increase font size
                  lineHeight: '1.5',  // Adjust line height for better readability
                  marginBottom: '30px'  // Add some space below the paragraph
                }}>
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                  style={{fontSize: '1.2em'}}  // Increase button text size
                >
                  Let's Share Ideas
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
