import { Image } from "./image";
import React, { useEffect } from 'react';

export const Gallery = (props) => {
  useEffect(() => {
    const galleryElement = document.getElementById('gallery');
    if (galleryElement) {
      galleryElement.style.backgroundColor = '#F7F7EF';
    }
  }, []);

  return (
    <div id="gallery">
      <div className="container">
        <div className="section-title">
        <h2>Learn Our Approach</h2>
        <p>Gain valuable insights to help your business thrive in this vast river of opportunity.</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
